const intercom = {
    boot (user, lang) {
        if (process.server) {
            return
        }

        if (user) {
            const name = `${user.first_name} ${user.last_name}`
            window.Intercom('boot', {
                app_id: process.env.INTERCOM_APP_ID,
                user_id: user.id,
                user_hash: user.id_hash,
                language_override: lang ?? 'ar',
                name,
                email: user.email,
                phone: user.phone,
                custom_launcher_selector: '.intercom-launcher'
            })
            return
        }

        window.Intercom('boot', { app_id: process.env.INTERCOM_APP_ID, language_override: lang ?? 'ar', custom_launcher_selector: '.intercom-launcher' })
    },

    update (user, lang) {
        if (process.server) {
            return
        }

        if (user) {
            const name = `${user.first_name} ${user.last_name}`
            window.Intercom('update', {
                app_id: process.env.INTERCOM_APP_ID,
                user_id: user.id,
                user_hash: user.id_hash,
                name,
                email: user.email,
                phone: user.phone,
                language_override: lang ?? 'ar',
                last_request_at: parseInt((new Date()).getTime() / 1000),
                custom_launcher_selector: '.intercom-launcher'
            })

            return
        }

        window.Intercom('update', {
            app_id: process.env.INTERCOM_APP_ID,
            language_override: lang ?? 'ar',
            last_request_at: parseInt((new Date()).getTime() / 1000),
            custom_launcher_selector: '.intercom-launcher'
        }
        )
    },

    shutdown () {
        window.Intercom('shutdown', {
            app_id: process.env.INTERCOM_APP_ID,
            custom_launcher_selector: '.intercom-launcher'
        })

        this.update(null)
    }
}

export default intercom
